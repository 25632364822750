import React from 'react'
import styled from 'styled-components'

// theme
import { greyLight } from '../colors'

const Line = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${greyLight};
    background-color: ${props => props.background};
`
const Hr = ({ background }) => {
    return <Line background={background} />
}

export default Hr
