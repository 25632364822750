import React, { Component } from 'react'
import * as firebase from 'firebase/app'
import 'firebase/database'
import isArray from 'lodash/isArray'
import { Flex } from '@rebass/grid'

// containers
import GuestListContainer from './GuestListContainer'

// ENV VAR
const {
    API_KEY,
    AUTH_DOMAIN,
    DB_URL,
    PROJECT_ID,
    STORAGE_BUCKET,
    SENDER_ID,
} = process.env

class GuestManagerContainer extends Component {
    state = {
        loading: false,
        guests: [],
    }

    componentDidMount() {
        this.initFirebase()
    }

    initFirebase = () => {
        if (!firebase.apps.length) {
            firebase.initializeApp({
                apiKey: API_KEY,
                authDomain: AUTH_DOMAIN,
                databaseURL: DB_URL,
                projectId: PROJECT_ID,
                storageBucket: STORAGE_BUCKET,
                messagingSenderId: SENDER_ID,
            })
        }

        this.fetchGuests()
    }

    fetchGuests = () => {
        this.setState({ loading: true })
        const guests = firebase.database().ref('/guests')
        guests.on('value', snapshot => {
            const data = Object.values(snapshot.val())
            this.setState({
                guests: isArray(data) ? data : [],
                loading: false,
            })
        })
    }

    render() {
        const { guests = [], loading } = this.state
        return loading ? (
            <div>Loading</div>
        ) : (
            <Flex width={1} py={3} flexDirection="column" alignItems="center">
                <GuestListContainer guests={guests} />
            </Flex>
        )
    }
}

export default GuestManagerContainer
