import React from 'react'
import { Flex, Box } from '@rebass/grid'
import styled from 'styled-components'

// components
import { greyLight, secondaryDark } from '../colors'

// styled components
const Container = styled(Flex)`
    &:hover {
        cursor: pointer;
    }
`

const CheckContainer = styled(Flex)`
    height: 20px;
    width: 20px;
    border: 1px solid ${greyLight};
    border-radius: 50%;
`
const Check = styled(Box)`
    height: 10px;
    width: 10px;
    background: ${secondaryDark};
    border-radius: 50%;
`

const Label = styled.p`
    font-weight: 700;
`

const Checkbox = ({ label, active, handleClick }) => {
    return (
        <Container alignItems="center" onClick={handleClick}>
            <CheckContainer
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                mr={1}
            >
                {active && <Check />}
            </CheckContainer>
            <Label>{label}</Label>
        </Container>
    )
}

export default Checkbox
