import React from 'react'
import styled from 'styled-components'
import { Box, Flex } from '@rebass/grid'
import { space } from 'styled-system'

// components
import Hr from '../layout/Hr'

// theme
import { greyDark, primary, secondary } from '../colors'

// styled
const Container = styled(Box)`
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding: 0.5rem;
    width: 300px;
    position: ${props => (props.fixed ? 'fixed' : 'initial')};
    top: 32px;
`
const Title = styled.p`
    text-align: center;
`
const Row = styled(Flex).attrs({
    justifyContent: 'space-between',
    py: 1,
})``
const Label = styled(Box)`
    color: ${greyDark};
`
const Value = styled.p``
const Data = styled.span`
    ${space};
    color: ${({ color }) => (color ? color : secondary)};
`

const ResponseStats = props => {
    const {
        data: {
            totalInvites,
            totalGuests,
            numResponded,
            numAccepted,
            totalAttending,
            numDeclined,
            numNotResponded,
        } = {},
        fixed,
    } = props

    return (
        <Box width={300}>
            <Container fixed={fixed}>
                <Title>Response Stats</Title>
                <Hr />
                <Box py={2}>
                    <Row>
                        <Label>Responses: </Label>
                        <Value>
                            <Data>{numResponded}</Data>
                            <Data color={greyDark}>/{totalInvites}</Data>
                        </Value>
                    </Row>
                    <Row>
                        <Label>Waiting on: </Label>
                        <Value>
                            <Data>{numNotResponded}</Data>
                            <Data color={greyDark}>/{totalInvites}</Data>
                        </Value>
                    </Row>
                    <Row>
                        <Label>Accepted: </Label>
                        <Value>
                            <Data color={primary}>{numAccepted}</Data>
                            <Data color={greyDark}>/{numResponded}</Data>
                        </Value>
                    </Row>
                    <Row>
                        <Label>Declined: </Label>
                        <Value>
                            <Data color={greyDark}>{numDeclined}</Data>
                            <Data color={greyDark}>/{numResponded}</Data>
                        </Value>
                    </Row>
                    <Row>
                        <Label>Total Guests: </Label>
                        <Value>
                            <Data color={primary}>{totalAttending}</Data>
                            <Data color={greyDark}>/{totalGuests}</Data>
                        </Value>
                    </Row>
                </Box>
            </Container>
        </Box>
    )
}

export default ResponseStats
