import React, { Component } from 'react'
import * as firebase from 'firebase/app'
import { Flex, Box } from '@rebass/grid'

// components
import { Input } from '../components/forms/Inputs'
import Button from '../components/controls/Button'

class LoginContainer extends Component {
    state = {
        email: '',
        password: '',
        submitting: false,
    }

    setInputValue = (input, value) => {
        this.setState({
            [input]: value,
        })
    }

    login = () => {
        this.setState(
            {
                submitting: true,
            },
            () => {
                const { email, password } = this.state
                firebase
                    .auth()
                    .setPersistence('local')
                    .then(() => {
                        firebase
                            .auth()
                            .signInWithEmailAndPassword(email, password)
                            .then(() => {
                                this.props.handleSuccessfulSubmit()
                            })
                            .catch(err => {
                                this.setState({
                                    submitting: false,
                                })
                                console.error(err)
                            })
                    })
            }
        )
    }

    render() {
        return (
            <Flex
                width={1}
                py={5}
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
            >
                <Box>
                    <Box mb={2} width="300px">
                        <h3>Email</h3>
                        <Input
                            input="email"
                            handleChange={this.setInputValue}
                        />
                    </Box>
                    <Box mb={2}>
                        <h3>Password</h3>
                        <Input
                            input="password"
                            type="password"
                            handleChange={this.setInputValue}
                        />
                    </Box>
                    <Button
                        onClick={this.login}
                        loading={this.state.submitting}
                    >
                        Login
                    </Button>
                </Box>
            </Flex>
        )
    }
}

export default LoginContainer
