import React, { Component } from 'react'
import * as firebase from 'firebase/app'
import 'firebase/database'
import { Flex, Box } from '@rebass/grid'
import styled from 'styled-components'
import uuid from 'uuid/v4'

// components
import Popup from '../components/Popup'
import { Input } from '../components/forms/Inputs'
import Dropdown from '../components/forms/Dropdown'
import Checkbox from '../components/controls/Checkbox'
import Button from '../components/controls/Button'

// styled components

const Label = styled.h3`
    margin-bottom: 8px;
`

class AddGuestContainer extends Component {
    state = {
        id: '',
        name: '',
        email: '',
        address: '',
        hasPlusOne: false,
        allotedGuests: 1,
        showGuestForm: false,
        submitting: false,
        error: false,
    }

    componentDidMount() {
        const { guest: { id } = {} } = this.props
        if (id) {
            this.setFormValues()
        }
    }

    setFormValues = () => {
        const { guest = {} } = this.props
        this.setState({
            ...guest,
        })
    }

    toggleNewForm = () => {
        this.setState({
            showGuestForm: true,
        })
    }

    resetGuest = () => {
        const { resetGuest } = this.props
        this.setState({
            name: '',
            email: '',
            address: '',
            hasPlusOne: false,
            allotedGuests: 0,
            showGuestForm: false,
        })
        resetGuest()
    }

    setValue = (input, value) => {
        this.setState({
            [input]: value,
        })
    }

    submitGuest = () => {
        const { id, name, email, hasPlusOne, address } = this.state
        const { guest = {} } = this.props
        let { allotedGuests } = this.state
        if (allotedGuests === 1 && hasPlusOne) {
            allotedGuests = allotedGuests + 1
        }
        const uid = id ? id : uuid()

        console.log('NEW ALLOTED: ', allotedGuests)
        this.setState(
            {
                submitting: true,
            },
            () => {
                firebase
                    .database()
                    .ref('guests/' + uid)
                    .set({
                        ...guest,
                        id: uid,
                        name,
                        email,
                        allotedGuests,
                        hasPlusOne,
                        address,
                        responded: false,
                    })
                    .then(() => {
                        this.setState(
                            {
                                submitting: false,
                                showGuestForm: false,
                            },
                            () => {
                                this.resetGuest()
                            }
                        )
                    })
                    .catch(err => {
                        this.setState({
                            submitting: false,
                            error: true,
                        })
                        console.error(err)
                    })
            }
        )
    }

    render() {
        const {
            name,
            email,
            address,
            hasPlusOne,
            submitting,
            error,
        } = this.state
        const { showGuestForm, guest = {} } = this.props
        let { allotedGuests } = this.state
        if (!allotedGuests && hasPlusOne && guest.allotedGuests === 2) {
            allotedGuests = 1
        }

        return (
            <Popup isOpen={showGuestForm} onCloseClick={this.resetGuest}>
                <Flex py={5} px={3} flexDirection="column">
                    <Box mb={3}>
                        <Label>Guest(s) Name</Label>
                        <Input
                            input="name"
                            value={name}
                            handleChange={this.setValue}
                        />
                    </Box>

                    <Box mr={3}>
                        <Label>Number of Guests</Label>
                        <Flex mb={3} alignItems="center">
                            <Box width={100} mr={4}>
                                <Dropdown
                                    selectItem={input => {
                                        this.setValue(
                                            'allotedGuests',
                                            input.value
                                        )
                                    }}
                                    value={allotedGuests}
                                    items={[
                                        { name: '1', value: 1 },
                                        { name: '2', value: 2 },
                                    ]}
                                />
                            </Box>
                            {allotedGuests === 1 ? (
                                <Box>
                                    <Checkbox
                                        label="Add a Plus 1"
                                        active={hasPlusOne}
                                        handleClick={() => {
                                            this.setValue(
                                                'hasPlusOne',
                                                !hasPlusOne
                                            )
                                        }}
                                    />
                                </Box>
                            ) : null}
                        </Flex>
                    </Box>

                    <Box mb={3}>
                        <Label>Contact Email</Label>
                        <Input
                            input="email"
                            value={email}
                            handleChange={this.setValue}
                        />
                    </Box>
                    <Box mb={3}>
                        <Label>Shipping Address</Label>
                        <Input
                            input="address"
                            value={address}
                            handleChange={this.setValue}
                        />
                    </Box>
                    <Box py={2} width={1}>
                        <Button
                            disabled={!name}
                            onClick={this.submitGuest}
                            loading={submitting}
                        >
                            submit
                        </Button>
                    </Box>
                    {error && (
                        <p>Error saving guest, check console and try again.</p>
                    )}
                </Flex>
            </Popup>
        )
    }
}

export default AddGuestContainer
