import React from 'react'
import { Box, Flex } from '@rebass/grid'
import styled from 'styled-components'

// theme
import { primary, secondary, greyLight, greyDark } from '../colors'

// styled
const Container = styled(Flex)`
    &:nth-child(even) {
        background: ${greyLight};
    }
`

const NameLabel = styled.h3`
    width: 100%;
    margin: 0;
    color: ${props => props.color};
`
const EditButton = styled(Box)`
    cursor: pointer;
    width: 42px;
    &:hover {
        color: ${greyDark};
    }
`

const GuestInfo = props => {
    const { guest = {}, editGuest } = props
    const { name, attending, responded, attendingGuests, allotedGuests } = guest
    const color = !responded ? secondary : !attending ? greyDark : primary
    return (
        <Container p={2} justifyContent="space-between">
            <Box flex={1}>
                <NameLabel color={color}>{name}</NameLabel>
                {responded && attending ? (
                    <p>Guests Attending: {attendingGuests}</p>
                ) : !responded ? (
                    <p>Guests Alloted: {allotedGuests}</p>
                ) : null}
            </Box>
            {!responded ? (
                <EditButton
                    p={2}
                    onClick={() => {
                        editGuest(guest)
                    }}
                >
                    edit
                </EditButton>
            ) : null}
        </Container>
    )
}

export default GuestInfo
