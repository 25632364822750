import React, { Component } from 'react'
import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import isObject from 'lodash/isObject'
import { Box, Flex } from '@rebass/grid'
import styled from 'styled-components'

// containers
import Layout from '../containers/Layout'
import LoginContainer from '../containers/LoginContainer'
import GuestManagerContainer from '../containers/GuestManagerContainer'

// styled
const Wrapper = styled(Box).attrs({
    width: 1,
    px: 4,
    py: 5,
})``

// ENV VAR
const {
    API_KEY,
    AUTH_DOMAIN,
    DB_URL,
    PROJECT_ID,
    STORAGE_BUCKET,
    SENDER_ID,
} = process.env

class Admin extends Component {
    state = {
        initialized: false,
        authorized: false,
    }

    componentDidMount() {
        this.initFirebase()
    }

    initFirebase = () => {
        if (!firebase.apps.length) {
            firebase.initializeApp({
                apiKey: API_KEY,
                authDomain: AUTH_DOMAIN,
                databaseURL: DB_URL,
                projectId: PROJECT_ID,
                storageBucket: STORAGE_BUCKET,
                messagingSenderId: SENDER_ID,
            })
            firebase.auth().setPersistence('local')
        }
        this.setState(
            {
                initialized: true,
            },
            () => {
                this.checkAuth()
            }
        )
    }

    checkAuth = () => {
        const currentUser = firebase.auth().currentUser
        let authorized = false
        if (isObject(currentUser) && currentUser.uid) {
            const { uid, isAnonymous } = currentUser
            authorized = uid && !isAnonymous
        }
        this.setState({
            authorized,
        })
    }

    render() {
        const { initialized, authorized } = this.state
        return (
            <Layout>
                {initialized ? (
                    <Flex width={1} flexDirection="column" alignItems="center">
                        <Wrapper>
                            {authorized ? (
                                <GuestManagerContainer />
                            ) : (
                                <LoginContainer
                                    handleSuccessfulSubmit={this.checkAuth}
                                />
                            )}
                        </Wrapper>
                    </Flex>
                ) : null}
            </Layout>
        )
    }
}

export default Admin
