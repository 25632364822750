import React, { Component } from 'react'
import styled from 'styled-components'
import { Box } from '@rebass/grid'

// components
import { secondary, greyLight, greyDark, white } from '../colors'

// styled components
const Container = styled(Box)`
    position: relative;
    border: 1px solid ${({ focused }) => (focused ? greyDark : greyLight)};
`

const Input = styled.input.attrs({
    readyOnly: true,
})`
    outline: none;
    border: none;
    width: 100%;
    font-size: 18px;
    font-family: 'Brandon', Arial, Helvetica, sans-serif;
    padding: 0.5rem 1rem;
    cursor: pointer;
`

const DownChevron = styled.span`
    pointer-events: none;
    top: 15px;
    right: 8px;
    position: absolute;
    width: 14px;
    height: 14px;
    &:before {
        position: absolute;
        top: 0;
        left: 0;
        border-style: solid;
        border-width: 2px 2px 0 0;
        content: '';
        display: inline-block;
        height: 0.45em;
        width: 0.45em;
        transform: rotate(135deg);
    }
`

const DropdownList = styled(Box)`
    width: calc(100% + 2px);
    position: absolute;
    top: 100%;
    left: -1px;
    border: 1px solid ${greyDark};
    border-top: 1px solid ${greyLight};
    max-height: 310px;
    overflow-y: auto;
    background: ${white};
    z-index: 1;
`

const DropdownItem = styled(Box).attrs({
    py: 2,
    px: 2,
})`
    font-family: 'Brandon', Arial, Helvetica, sans-serif;
    font-size: 16px;
    color: ${secondary};
    border-bottom: 1px solid ${greyLight};
    &:hover {
        background: ${greyLight};
        cursor: pointer;
    }
    &:last-child {
        border-bottom: none;
    }
`

class Dropdown extends Component {
    state = {
        input: '',
        focused: false,
    }

    componentWillReceiveProps(nextProps) {
        const { value } = nextProps
        const { items = [] } = this.props
        const input = items.find(item => item.value === value)
        if (input && input !== this.state.input) {
            this.setState({ input: input.name })
        }
    }

    handleFocus = () => {
        this.setState({
            focused: true,
        })
    }

    handleValueChange = ({ target: { value } }) => {
        if (value === this.state.input) {
            return false
        }
        this.setState({ input: value }, () => {
            if (this.props.resetSelected) {
                this.props.resetSelected()
            }
        })
    }

    handleClickOutside = event => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
                focused: false,
            })
        }
    }

    handleItemClick = item => {
        this.setState(
            {
                input: item.name,
                focused: false,
            },
            () => {
                this.props.selectItem(item)
            }
        )
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside)
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside)
    }

    render() {
        const { placeholder, width = 1, items } = this.props
        const { focused, input } = this.state
        return (
            <Container width={width} focused={focused}>
                <DownChevron />
                <Input
                    placeholder={placeholder}
                    onChange={this.handleValueChange}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    value={input}
                    readOnly
                />
                {focused ? (
                    <DropdownList
                        ref={node => {
                            this.wrapperRef = node
                        }}
                    >
                        {items.map((item = {}, index) => (
                            <DropdownItem
                                key={item.name + index}
                                onClick={() => {
                                    this.handleItemClick(item)
                                }}
                            >
                                {item.name}
                            </DropdownItem>
                        ))}
                    </DropdownList>
                ) : null}
            </Container>
        )
    }
}

export default Dropdown
