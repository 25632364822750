import React, { Component, Fragment } from 'react'
import { Box, Flex } from '@rebass/grid'
import styled from 'styled-components'
import { maxWidth } from 'styled-system'
import _round from 'lodash/round'

// containers
import AddGuestContainer from './AddGuestContainer'

// components
import Hr from '../components/layout/Hr'
import ResponseStats from '../components/ResponseStats'
import GuestInfo from '../components/GuestInfo'

// theme
import { primary } from '../components/colors'

// styled components
const GuestsList = styled(Box).attrs({
    width: 1,
    mr: 3,
    p: 3,
    flex: 1,
})`
    ${maxWidth};
`

const Header = styled.h2`
    width: 100%;
    text-align: center;
    margin: 0;
    padding-bottom: 1rem;
`

const AddGuestButton = styled(Box)`
    position: fixed;
    z-index: 10;
    right: 20px;
    bottom: 20px;
    height: 75px;
    width: 75px;
    box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.25);
    background-color: ${primary};
    border-radius: 50%;
    cursor: pointer;
    &:before {
        position: absolute;
        content: '+';
        color: white;
        font-size: 50px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`

const sortGuests = guests => {
    return guests.sort((a, b) => {
        return a.name < b.name ? -1 : a.name > b.name ? 1 : 0
    })
}

class GuestListContainer extends Component {
    state = {
        guest: {},
        showGuestForm: false,
        fixResponse: false,
    }

    componentDidMount() {
        document.addEventListener('scroll', this.detectFixResponse)
        if (window.scrollY >= 131) {
            this.setState({
                fixResponse: true,
            })
        }
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.detectFixResponse)
    }

    calculateResponseData = () => {
        const { guests = [] } = this.props
        const totalInvites = guests.length
        const totalGuests = guests.reduce((total, guest) => {
            return total + guest.allotedGuests
        }, 0)

        const responded = guests.filter(guest => guest.responded)
        const numResponded = responded.length
        const percResponded = _round((numResponded / totalInvites) * 100, 0)

        const accepted = responded.filter(guest => guest.attending)
        const numAccepted = accepted.length
        const totalAttending = accepted.reduce((total, guest) => {
            return total + guest.attendingGuests
        }, 0)

        const declined = responded.filter(guest => !guest.attending)
        const numDeclined = declined.length

        const numNotResponded = guests.filter(guest => !guest.responded).length
        const percNotResponded = _round(
            (numNotResponded / totalInvites) * 100,
            0
        )

        return {
            totalInvites,
            totalGuests,
            numResponded,
            percResponded,
            numAccepted,
            totalAttending,
            numDeclined,
            numNotResponded,
            percNotResponded,
        }
    }

    newGuest = () => {
        this.setState({
            guest: {},
            showGuestForm: true,
        })
    }

    editGuest = guest => {
        this.setState({
            guest,
            showGuestForm: true,
        })
    }

    resetGuest = () => {
        this.setState({
            guest: {},
            showGuestForm: false,
        })
    }

    detectFixResponse = () => {
        if (window.scrollY >= 131) {
            this.setState({
                fixResponse: true,
            })
        } else {
            this.setState({
                fixResponse: false,
            })
        }
    }

    render() {
        const { showGuestForm, guest, fixResponse } = this.state
        const { guests } = this.props
        const responseData = this.calculateResponseData()

        return (
            <Fragment>
                <Box mb={4} width={900}>
                    <Header>Guestlist</Header>
                    <Hr />
                </Box>
                <Flex width={1} justifyContent="center" alignItems="flex-start">
                    <GuestsList>
                        {sortGuests(guests).map(guest => {
                            return (
                                <GuestInfo
                                    guest={guest}
                                    key={guest.id}
                                    editGuest={this.editGuest}
                                />
                            )
                        })}
                        <AddGuestButton onClick={this.newGuest} />
                        {showGuestForm && (
                            <AddGuestContainer
                                guest={guest}
                                resetGuest={this.resetGuest}
                                showGuestForm={showGuestForm}
                            />
                        )}
                    </GuestsList>
                    <ResponseStats data={responseData} fixed={fixResponse} />
                </Flex>
            </Fragment>
        )
    }
}

export default GuestListContainer
